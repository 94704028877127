<template>
  <div>
    <div class="wrapper">
      <div class="input-item mb-btn">
        <van-field
          v-model="phone"
          label=""
          placeholder="请输入手机号"
          maxlength="11"
        >
        </van-field>
      </div>
      <p class="explain">*绑定手机号需与系统账户登录用手机号保持一致</p>
      <div class="input-item mb-btn-2">
        <van-field
          v-model="captcha"
          label=""
          maxlength="6"
          placeholder="请输入验证码"
        >
          <template #button>
            <span
              class="send-text"
              @click="sendVerify()"
              :class="{ 'disable-text': countDown.status === 'run' }"
            >
              {{
                countDown.status === 'run'
                  ? `${countDown.duration}s后重发`
                  : '发送验证码'
              }}
            </span>
          </template>
        </van-field>
      </div>
      <van-button class="big-btn" @click="submit">绑定</van-button>
    </div>
    <van-overlay :show="visiable">
      <div class="modal">
        <div v-if="flag">
          <img src="~@/assets/image/icon/dh.png" class="modal-icon" />
          <p class="modal-detail">绑定成功!</p>
        </div>
        <div v-else>
          <img src="~@/assets/image/icon/th.png" class="modal-icon" />
          <p class="modal-detail">绑定不成功,<br />请重新输入!</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { userTokenKey } from '@/config/index'
import { sendSMS, wxLoginCode } from '@/api/login'
export default {
  data() {
    return {
      phone: '',
      captcha: '',
      openId: '',
      phoneReg: new RegExp(
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      ),
      countDown: {
        timer: null,
        duration: 60,
        status: 'stop',
        start: () => {
          this.countDown.timer =
            this.countDown.timer ||
            setInterval(() => {
              this.countDown.status = 'run'
              this.countDown.duration -= 1
              if (this.countDown.duration <= 0) {
                this.countDown.stop()
              }
            }, 1000)
        },
        stop: () => {
          clearInterval(this.countDown.timer)
          this.countDown.timer = null
          this.countDown.status = 'stop'
          this.countDown.duration = 60
        },
      },
      visiable: false,
      flag: false,
    }
  },
  created() {
    console.log(this.$route.query)
    this.openId = this.$route.query.info.result.openid
  },
  computed: {},
  methods: {
    ...mapActions(['setUser']),
    sendVerify() {
      // 发送验证码
      if (this.countDown.status === 'run') return

      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      }

      const params = {
        mobile: this.phone,
        smsmode: '0',
      }

      sendSMS(params).then((res) => {
        if (res && res.success) {
          this.$toast.success('验证码发送成功，请查收')
          this.startCountDown()
        }
      })
    },
    startCountDown() {
      // 开始倒计时
      this.countDown.start()
    },
    async submit() {
      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      } else if (!this.captcha) {
        this.$toast.fail('请输入验证码')
        return
      }

      let res = await wxLoginCode({
        mobile: this.phone,
        type: 1,
        openId: this.openId,
        captcha: this.captcha,
      })
      console.log(res)
      // this.visiable = true

      if (res.code == 200) {
        const data = res.result

        // this.flag = true

        const { token, userInfo } = data

        this.$cookie.set(userTokenKey, token, { expires: 1 })
        this.setUser(userInfo)
        this.$router.replace('/')
      } else {
        this.flag = true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.wrapper {
  border-radius: 56px;
  background-color: #fff;
  // box-shadow: 0 0 10px rgba(3, 3, 3, 0.05);
  padding: 240px 56px 120px;
  margin: 0 62px;
  margin-top: 600px;
}
.send-text {
  color: @primary-color;
}
.disable-text {
  color: @primary-font-color;
}
.mb-btn {
  margin-bottom: 24px;
}
.explain {
  font-size: 40px;
  font-weight: 400;
  color: #010101;
  margin-left: 96px;
  margin-bottom: 56px;
}
.mb-btn-2 {
  margin-bottom: 112px;
}
.big-btn {
  width: 100%;
  color: #fff;
  font-size: 56px;
  height: 162px;
  border-radius: 80px;
  background: linear-gradient(
    to right,
    rgba(255, 137, 28, 1),
    rgba(255, 85, 8, 1)
  );
}
.input-item {
  background-color: #f7f7fa;
  border-radius: 80px;
  height: 162px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.modal {
  margin: 762px 162px;
  width: 960px;
  height: 600px;
  background: #ffffff;
  border-radius: 32px;
  text-align: center;
  &-icon {
    width: 121px;
    margin-top: 159px;
  }
  &-detail {
    font-size: 54px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    margin-top: 80px;
  }
}
</style>
